import {useState} from 'react';
import {Button} from 'devextreme-react';
import notify from 'devextreme/ui/notify';

import Loader from '../../../../Components/Loader';
import {TOASTER_DELAY} from '../../../../Constants/values';
import {DownloadBase64File} from '../../../../Utils/FileHandler';
import {GameResultReportPayloadDTO} from "../../../../Models/Reports/GameResultReport";
import ReportingService from "../../../../Services/Reporting/ReportingService";

type Props = {
    gameResults: GameResultReportPayloadDTO;
    paginationData: any;
};

const GameResultReportExportBtn = ({gameResults, paginationData}: Props) => {
    const [Loading, setLoading] = useState(false);

    const handleExport = () => {
        setLoading(true);

        const payload: GameResultReportPayloadDTO = {
            pageNumber: paginationData?.pageNumber,
            pageSize: gameResults.pageSize,
            startDate: gameResults.startDate,
            endDate: gameResults.endDate
        }

        ReportingService.exportGameResultReport(payload)
            .then((res) => {
                DownloadBase64File(
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'GameReportsTransactionReport.xlsx',
                    res
                );
                notify('Successfully downloaded', 'success', TOASTER_DELAY);

                setLoading(false);
            })
            .catch((err) => {
                err?.response?.data.text().then((res: string) => {
                    const errorMsg = res;
                    errorMsg && notify(errorMsg, 'error', TOASTER_DELAY);
                    setLoading(false);
                });
            });
    };

    return (
        <article>
            <Button
                text="Export"
                type="default"
                id="topupSearch"
                useSubmitBehavior={true}
                onClick={handleExport}
            />
            <Loader isLoaderVisible={Loading}/>
        </article>
    );
};

export default GameResultReportExportBtn;
