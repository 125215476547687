import { TreeView } from "devextreme-react";
import React, { useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";

import { useSideBarItems } from "../../hooks/useSideBarItems";
import { ROUTES } from "../../routes";
import AppVersion from "../AppVersion";
import {ReactComponent as LogoIcon} from "../../Assets/images/logo.svg";

import "./Sidebar.scss";

type Props = {};
export const Sidebar = React.memo(({}: Props) => {
  const navigation = useNavigate();

  const location = useLocation();
  const [sidebarMenuItems] = useSideBarItems();
  const [sidebarItem, setSidebarItems] = useState([
    ...sidebarMenuItems.map((item) => JSON.parse(JSON.stringify(item))),
  ]);
  const [selectedItemId, setSelectedItemId] = useState<number>();

  //  handleSelectedItem responsible for highlighting the clicked item
  const handleRefreshSelectedItem = () => {
    // countIndex keep tracking the number of sidebar items (parent and children)
    let countIndex = 0;
    const newListItems = [
      ...sidebarMenuItems.map((item) => JSON.parse(JSON.stringify(item))),
    ];
    // Create an array That combine all items
    // parent and sub item with keeping the same order of the sidebar
    let result: any = [];
    newListItems.map((item) => {
      if (item.hasItems) {
        result.push(item);
        item.items.forEach((item: any) => result.push(item));
      } else {
        result.push(item);
      }
    });
    result.forEach((element: any, index: number) => {
      if (location.pathname === "/") {
        setSelectedItemId(0);
      }
      if (location.pathname.includes(element.id)) {
        setSelectedItemId(index);
      }
    });
    countIndex++;
  };
  const handleSelectedItem = (id: number) => {
    // countIndex keep tracking the number of sidebar items (parent and children)
    let countIndex = 0;
    const newListItems = [
      ...sidebarMenuItems.map((item) => JSON.parse(JSON.stringify(item))),
    ];
    // Create an array That combine all items
    // parent and sub item with keeping the same order of the sidebar
    let result: any = [];
    newListItems.map((item) => {
      if (item.hasItems) {
        result.push(item);
        item.items.forEach((item: any) => result.push(item));
      } else {
        result.push(item);
      }
    });
    // if the item has child it will be not highlighted
    if (id !== undefined) {
      result[id].selected = true;
      result[id].expanded = true;
    }
    // update the new sidebar Items
    setSidebarItems(newListItems);
    countIndex++;
  };
  useLayoutEffect(() => {
    selectedItemId !== undefined && handleSelectedItem(selectedItemId);
  }, [selectedItemId, location.pathname]);
  useLayoutEffect(() => {
    setSidebarItems([
      ...sidebarMenuItems.map((item) => JSON.parse(JSON.stringify(item))),
    ]);
    selectedItemId !== undefined && handleSelectedItem(selectedItemId);
  }, [location.pathname]);

  return (
    <aside className="aside-container">
      <Link to={"/"}>
        <h3 className="aside-logo-text"><LogoIcon className="aside-logo-icon"/></h3>
      </Link>
      <TreeView
        id="simple-treeview"
        style={{
          marginTop: "-30px",
        }}
        items={sidebarItem}
        width={250}
        expandEvent="click"
        activeStateEnabled={true}
        virtualModeEnabled={true}
        onItemRendered={(e) => {
          const itemId = e.itemData?.id + "";
          const itemText = e.itemElement?.textContent?.replaceAll(" ", "");
          if (itemText) {
            if (itemId.includes("multilevel")) {
              e.itemElement?.parentElement?.classList.remove(
                "dx-state-selected"
              );
            }
            e.itemElement?.setAttribute("id", `sidebar_${itemText}`);
          }
          handleRefreshSelectedItem();
        }}
        onItemClick={(e) => {
          const itemIndex = e.itemIndex ? +e.itemIndex : 0;
          setSelectedItemId(itemIndex);
          const location = e.itemData?.id + "";
          if (!location.includes("multilevel")) {
            navigation(location ?? ROUTES.HOME, { replace: true });
          } else {
            const firstItemLocation =
              e.itemData && e.itemData.items?.length
                ? e.itemData.items[0].id + ""
                : "";
            navigation(firstItemLocation ?? ROUTES.HOME, {
              replace: true,
            });
          }
        }}
      />
      <AppVersion />
    </aside>
  );
});
