import CreditService from "../../Services/Credit/CreditService";
import React, {useEffect, useState} from "react";
import ButtonBox from "devextreme-react/button";
import {Column, DataGrid,} from "devextreme-react/data-grid";
import Loader from "../../Components/Loader";

import AddCreditPopup from "./AddCredit/AddCredit";
import notify from "devextreme/ui/notify";
import {COUNTRY_CODES, TOASTER_DELAY} from "../../Constants/values";
import "./Credit.scss";
import EditCreditPopup from "./EditCredit/EditCredit";
import {confirm} from "devextreme/ui/dialog";
import AssetsService from "../../Services/Assets/AssetsService";

type CreditRates = {
    id: string;
    rate: string;
    currencyIso3: string;
    countryIso2: string,
    paymentMethodIds: number[] | any
    default?: boolean
}
const Credit = () => {
    const [creditList, setCreditList] = useState<any[]>();
    const [loading, setLoading] = useState(false);
    const [isAddPopupVisible, setAddPopupVisibility] = useState(false);
    const [isEditPopupVisible, setEditPopupVisibility] = useState(false);
    const [CreditRates, setCreditRates] = useState<CreditRates>({
        id: "",
        rate: "",
        currencyIso3: "",
        countryIso2: "",
        paymentMethodIds: []
    });
    const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
    const [currency, setCurrency] = useState<any[]>([]);

    const getPaymentMethodsHandler = () => {
        CreditService.getPaymentMethods().then((response) => {

            const paymentMethods = Object.keys(response).map((key) => {
                return {
                    id: parseInt(key),
                    name: response[key]
                }
            });

            setPaymentMethods(paymentMethods);
        }).catch(() => {
            notify("Error while fetching payment methods", "error", TOASTER_DELAY);
        });
    }

    const getCredit = () => {
        setLoading(true)
        const payload = {
            PageNumber: 1,
            PageSize: 10,
        }
        CreditService.getCredit(payload).then((response) => {
                const credits = response.result;
                setLoading(false)
                setCreditList(credits)
            }
        ).catch(() => {
            notify("Error while fetching credit rates", "error", TOASTER_DELAY);
            setLoading(false)
        })
    }

    const editCreditHandler = (e: any) => {
        const paymentMethods = Object.keys(e.row.data.paymentMethods).map((key) => {
            return parseInt(key)
        });

        setCreditRates({
            id: e.row.data.id,
            rate: e.row.data.rate,
            currencyIso3: e.row.data.currencyIso3,
            countryIso2: e.row.data.countryIso2,
            paymentMethodIds: paymentMethods,
            default: e.row.data.default
        });

        setEditPopupVisibility(true);
    }

    const deleteCreditHandler = React.useCallback((e) => {
        let result = confirm(
            "<i>Are you sure you want to delete this credit rate? <b>",
            "Confirm credit rate deletion"
        );

        result.then((dialogResult) => {
            if (dialogResult) {
                CreditService.deleteCredit(e.row.data.id)
                    .then((r) => {
                        notify("Credit rate deleted successfully", "success", TOASTER_DELAY);
                    })
                    .catch(() => {
                        notify("Error while deleting credit rate.", "error", TOASTER_DELAY);
                    })
                    .finally(() => getCredit());
            }
        });
    }, []);

    const toggleAddPopupVisibility = () => {
        setAddPopupVisibility(!isAddPopupVisible);
    }

    const toggleEditPopupVisibility = () => {
        setEditPopupVisibility(!isEditPopupVisible);
    }

    const getCurrencyHandler = () => {
        setLoading(true);
        AssetsService.getAvailableCurrency().then((response) => {
            setCurrency(response);
            setLoading(false);
        }).catch((err) => {
            console.log(err);
            notify("Error while fetching currencies", "error", TOASTER_DELAY);
            setLoading(false);
        });
    }

    useEffect(() => {
        getCredit();
        getPaymentMethodsHandler()
        getCurrencyHandler();
    }, []);

    return (
        <div>
            <h1>Credit Rates</h1>
            <div>Manage country credits rates from here.</div>
            <ButtonBox
                text="Add Country Credit"
                className="addCredit"
                onClick={() => {
                    setCreditRates({
                        id: "",
                        rate: "",
                        currencyIso3: "",
                        countryIso2: "",
                        paymentMethodIds: []
                    })
                    setAddPopupVisibility(true)
                }}
            ></ButtonBox>

            <DataGrid
                keyExpr="id"
                id="gridContainer"
                className="grid-table"
                dataSource={creditList}
                showBorders={true}
            >
                <Column dataField="countryIso2" caption="Country"
                        cellRender={(e) => {
                            const formattedCountryCode = COUNTRY_CODES.find((country) => country.code === e.value);

                            return formattedCountryCode?.name || e.value;
                        }}
                />
                <Column dataField="currencyIso3" caption="Currency"/>
                <Column dataField="paymentMethods" caption="Payment Methods"
                        cellRender={(e) => {
                            const paymentMethods = e.value

                            return Object.keys(paymentMethods).map((key) => {
                                return paymentMethods[key]
                            }).join(", ");
                        }}
                />
                <Column dataField="rate" caption="Rate" alignment="left"
                        cellRender={(e) => {
                            const currency = e.data.currencyIso3;
                            const rate = e.value;

                            return `1 Credit = ${rate} ${currency}`;

                        }}
                />
                <Column dataField="createdOn" caption="Created On" format="dd/MM/yyyy" dataType="date"/>
                <Column type="buttons" buttons={
                    [
                        {
                            name: "editCredit",
                            onClick: editCreditHandler,
                            icon: "edit",
                        },
                        {
                            name: "deleteCredit",
                            onClick: deleteCreditHandler,
                            visible: (e: { row: { data: { default: boolean; }; }; }) => !e.row.data.default,
                            icon: "trash",
                        },
                    ]
                } width="auto" allowEditing={false}></Column>
            </DataGrid>

            <Loader isLoaderVisible={loading}/>

            {isAddPopupVisible && <AddCreditPopup
                paymentMethods={paymentMethods}
                isAddPopupVisible={isAddPopupVisible}
                addPopupVisibilityHandler={toggleAddPopupVisibility}
                fetchCreditList={getCredit}
                creditRates={CreditRates}
                creditRatesHandler={setCreditRates}
                loadingHandler={setLoading}
                currency={currency}
            />}

            {isEditPopupVisible && <EditCreditPopup
                paymentMethods={paymentMethods}
                isEditPopupVisible={isEditPopupVisible}
                editPopupVisibilityHandler={toggleEditPopupVisibility}
                fetchCreditList={getCredit}
                creditRates={CreditRates}
                creditRatesHandler={setCreditRates}
                loadingHandler={setLoading}
                currency={currency}
            />}

        </div>
    )
}

export default Credit;