import "devextreme/dist/css/dx.light.css";
import {Suspense} from "react";
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";

import ChangePassword from "./Pages/Account/ChangePassword";
import BackendUsers from "./Pages/BackendUsers/BackendUsers";
import Blackout from "./Pages/Blackout/Blackout";
import Clients from "./Pages/Clients/Clients";
import {Contact} from "./Pages/Contact/Contact";
import Credit from "./Pages/Credit/Credit";
import Dashboard from "./Pages/Dashboard/Dashboard";
import {PageNotFound} from "./Pages/Errors/PageNotFound";
import Login from "./Pages/Login/Login";
import {PrivacyPolicy} from "./Pages/PrivacyPolicy/PrivacyPolicy";
import Roles from "./Pages/Roles/Roles";
import Rules from "./Pages/RulesEngine/Rules/Rules";
import Simulations from "./Pages/RulesEngine/Simulations/Simulations";
import {TermsOfService} from "./Pages/TermsOfService/TermsOfService";
import Transactions from "./Pages/Transactions/Transactions";
import Users from "./Pages/Users/Users";
import ProtectedRoute from "./ProtectedRoute";
import {CustomerReport} from "./Pages/Reports/CustomerReport/CustomerReport";
import {GameResultReport} from "./Pages/Reports/GameResultReport/GameResultReport";
import {PaymentsReport} from "./Pages/Reports/PaymentsReport/PaymentsReport";
import {RevenueReportReport} from "./Pages/Reports/RevenueReport/RevenueReport";
import Broadcast from "./Pages/Credit/BulkCredit/Broadcast";
import ResetCache from "./Pages/Settings/ResetCache/ResetCache";

import {ROUTES} from "./routes";
import SubscriptionConfiguration from "./Pages/Settings/SubscriptionConfiguration/SubscriptionConfiguration";

function App() {
    return (
        <Suspense fallback={"Loading..."}>
            <>
                <div className="main-layout">
                    <Router basename={ROUTES.HOME}>
                        <Routes>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                            <Route path="/terms-of-service" element={<TermsOfService/>}/>
                            <Route path="/contact" element={<Contact/>}/>
                            <Route path={ROUTES.HOME} element={<ProtectedRoute/>}>
                                <Route path={ROUTES.DASHBOARD} element={<Dashboard/>}/>
                                <Route path={ROUTES.HOME} element={<Dashboard/>}/>
                                <Route path={ROUTES.BACKEND_USERS} element={<BackendUsers/>}/>
                                <Route path={ROUTES.CREDIT} element={<Credit/>}/>
                                <Route path={ROUTES.BROADCAST} element={<Broadcast/>}/>
                                <Route path={ROUTES.TRANSACTIONS} element={<Transactions/>}/>

                                {/*<Route path={ROUTES.USERS} element={<Users/>}/>*/}

                                <Route path={ROUTES.ROLES} element={<Roles/>}/>
                                <Route path={ROUTES.CLIENTS} element={<Clients/>}/>
                                <Route path={ROUTES.ACCOUNT} element={<ChangePassword/>}/>
                                <Route path={ROUTES.RULES} element={<Rules/>}/>
                                <Route path={ROUTES.SIMULATIONS} element={<Simulations/>}/>
                                <Route path={ROUTES.BLACKOUT} element={<Blackout/>}/>

                                <Route path={ROUTES.CUSTOMERSREPORT} element={<CustomerReport/>}/>
                                <Route path={ROUTES.GAMERESULTSREPORT} element={<GameResultReport/>}/>
                                <Route path={ROUTES.PAYMENTSREPORT} element={<PaymentsReport/>}/>
                                <Route path={ROUTES.REVENUEREPORT} element={<RevenueReportReport/>}/>

                                <Route path={ROUTES.RESET_CACHE} element={<ResetCache/>}/>
                                <Route path={ROUTES.SUBSCRIPTION_CONFIGURATION} element={<SubscriptionConfiguration/>}/>
                            </Route>
                            <Route path="*" element={<PageNotFound/>}/>
                        </Routes>
                    </Router>
                </div>
            </>
        </Suspense>
    );
}

export default App;
