import Popup from "devextreme-react/popup";
import React from "react";
import {CheckBox, SelectBox} from "devextreme-react";

import "./Style.scss";
import {COUNTRY_CODES, TOASTER_DELAY} from "../../../../Constants/values";
import Validator, {PatternRule, RequiredRule} from "devextreme-react/validator";
import TextBox from "devextreme-react/text-box";
import Button from "devextreme-react/button";
import checkValidation from "../../../../Utils/FormValidation";
import notify from "devextreme/ui/notify";

type SubscriptionRate = {
    currencyIso3: string;
    amount: number;
    countryIso2: string | null;
    defaultRate?: boolean;
}

type AddRateProps = {
    showAddRate: boolean;
    closeAddRateHandler: (value: boolean) => void;
    setRateList: (value: SubscriptionRate[]) => void;
    rateList: SubscriptionRate[];
    rate: SubscriptionRate;
    editMode: boolean;
    setEditMode: (value: boolean) => void;
    setRate: (value: SubscriptionRate) => void;
    currency: any[];
}
const AddRate = ({
                     showAddRate,
                     closeAddRateHandler,
                     rateList,
                     setRateList,
                     setRate,
                     rate,
                     editMode,
                     setEditMode,
                     currency
                 }: AddRateProps) => {

    const addEditRateHandler = () => {
        if (editMode) {
            const updatedRateList = rateList.map((r) => {
                if (r.countryIso2 === rate.countryIso2) {
                    return rate;
                }
                return r;
            });

            setRateList(updatedRateList);
            setEditMode(false);
            closeAddRateHandler(false);
        } else {
            const duplicate = rateList.find((r) => r.countryIso2 === rate.countryIso2);
            if (duplicate && !rate.defaultRate) {
                notify("Rate for this country already exists", "error", TOASTER_DELAY);
                return;
            }

            setRateList([...rateList,
                {...rate, countryIso2: rate.defaultRate ? null : rate.countryIso2}
            ]);

            closeAddRateHandler(false);
        }
    };

    const defaultRateChecker = () => {
        return rateList.some((r) => r.defaultRate);
    }

    return (
        <Popup
            width={"30vw"}
            height={"50vh"}
            showTitle={true}
            title={`${editMode ? "Edit" : "Add"} Rate`}
            dragEnabled={false}
            visible={showAddRate}
            onHiding={() => {
                closeAddRateHandler(false)
                setEditMode(false);
            }}
        >
            <div>
                <div className="addRateTextboxContainer">
                    {!defaultRateChecker() &&
                        <CheckBox
                            text="Default Rate"
                            value={rate?.defaultRate || false}
                            onValueChanged={(e) => {
                                setRate({...rate, defaultRate: e.value});
                            }}
                        ></CheckBox>
                    }
                </div>

                <div className="addRateTextboxContainer">
                    <SelectBox
                        dataSource={COUNTRY_CODES}
                        displayExpr={"name"}
                        disabled={rate.defaultRate}
                        value={
                            rate?.countryIso2 || null
                        }
                        valueExpr={"code"}
                        searchEnabled={true}
                        onValueChanged={(e) => {
                            setRate({...rate, countryIso2: e.value});
                        }}
                        placeholder="Country"
                    >
                        <Validator>
                            {
                                rate.defaultRate ? null : <RequiredRule message="Country is required"/>
                            }
                        </Validator>
                    </SelectBox>
                </div>

                <div className="addRateTextboxContainer">
                    <SelectBox
                        dataSource={currency}
                        value={rate?.currencyIso3 || null}
                        onValueChanged={
                            (e) => {
                                setRate({...rate, currencyIso3: e.value});
                            }
                        }
                        placeholder="Currency"
                        searchEnabled={true}
                        searchMode="contains"
                        searchExpr="this"
                        searchTimeout={200}
                        minSearchLength={0}
                        showClearButton={true}
                    >
                        <Validator>
                            <RequiredRule message="Currency is required"/>
                        </Validator>
                    </SelectBox>
                </div>

                <div className="addRateTextboxContainer">
                    <TextBox
                        placeholder="Amount"
                        value={String(rate?.amount || "")}
                        onValueChange={(e) => {
                            setRate({...rate, amount: Number(e)});
                        }}
                    >
                        <Validator>
                            <RequiredRule message="Amount is required"/>
                            <PatternRule
                                message="Amount must be a number"
                                pattern={/^\d+(\.\d+)?$/}
                            />
                        </Validator>
                    </TextBox>
                </div>

                <div className="textboxContainer">
                    <Button
                        text={`${editMode ? "Edit" : "Add"} Rate`}
                        onClick={(e) => checkValidation(e, addEditRateHandler)}
                    />
                </div>
            </div>
        </Popup>
    );
}

export default AddRate;