import FactoryService from "../FactoryService";
import AccountingBaseService from "../AccountingBaseService";

const factoryService = new FactoryService();

class AssetsService {
    private CURRENCY_ENDPOINT = "api/Currencies/available";

    async getAvailableCurrency() {
        const result = await (
            await factoryService.create(AccountingBaseService)
        ).getAsJson(`${this.CURRENCY_ENDPOINT}`, true);
        if (result) {
            return result;
        }
        return null;
    }
}

export default new AssetsService();
