import {SIDEBAR_ITEMS} from "../Constants/values";
import {ROUTES} from "../routes";

//assets
import ChartIcon from "../Assets/images/icons/Chart.svg";
import BackendUsersIcon from "../Assets/images/icons/Document.svg";
import ChatIcon from "../Assets/images/icons/Broadcast.svg";
import WalletIcon from "../Assets/images/icons/Credit-Rate.svg";
import SettingIcon from "../Assets/images/icons/Setting.svg";
import UserIcon from "../Assets/images/icons/Role.svg";
import AccountIcon from "../Assets/images/icons/Account.svg";
import ReportIcon from "../Assets/images/icons/Report.svg";
import RuleEngineIcon from "../Assets/images/icons/Rule-Engine.svg";

const {
    REACT_APP_PAYMENT_MODEL
} = (window as any).__env__;

export const useSideBarItems = () => {
    const sidebarMenuItems = [
        {
            id: ROUTES.DASHBOARD,
            text: SIDEBAR_ITEMS.DASHBOARD,
            icon: ChartIcon,
            location: ROUTES.DASHBOARD,
        },
        // {
        //     id: ROUTES.USERS,
        //     text: SIDEBAR_ITEMS.CUSTOMERS,
        //     icon: "group",
        //     location: ROUTES.USERS,
        // },
        {
            id: ROUTES.BACKEND_USERS,
            text: SIDEBAR_ITEMS.BACKEND_USERS,
            icon: BackendUsersIcon,
            location: ROUTES.BACKEND_USERS,
        },

        {
            id: ROUTES.BROADCAST,
            text: SIDEBAR_ITEMS.BROADCAST,
            icon: ChatIcon,
            location: ROUTES.BROADCAST,
        },
        ...REACT_APP_PAYMENT_MODEL === "CREDIT" ? [
            {
                id: ROUTES.CREDIT,
                text: SIDEBAR_ITEMS.CREDIT,
                icon: WalletIcon,
                location: ROUTES.CREDIT,
            },
        ] : [],
        ...REACT_APP_PAYMENT_MODEL !== "CREDIT" ? [
            {
                id: ROUTES.SUBSCRIPTION_CONFIGURATION,
                text: SIDEBAR_ITEMS.SUBSCRIPTION_CONFIGURATION,
                icon: WalletIcon,
                location: ROUTES.SUBSCRIPTION_CONFIGURATION,
            },
        ] : [],
        {
            id: ROUTES.BLACKOUT,
            text: SIDEBAR_ITEMS.BLACKOUT,
            icon: SettingIcon,
            location: ROUTES.BLACKOUT,
        },
        {
            id: ROUTES.ROLES,
            text: SIDEBAR_ITEMS.ROLES,
            icon: UserIcon,
            location: ROUTES.ROLES,
        },
        {
            id: ROUTES.CLIENTS,
            text: SIDEBAR_ITEMS.CLIENTS,
            icon: UserIcon,
            location: ROUTES.CLIENTS,
        },
        {
            id: ROUTES.ACCOUNT,
            text: SIDEBAR_ITEMS.ACCOUNT,
            icon: AccountIcon,
            location: ROUTES.ACCOUNT,
        },
        ...REACT_APP_PAYMENT_MODEL === "CREDIT" ? [
            {
                id: "multilevel-1",
                text: SIDEBAR_ITEMS.RULE_ENGINE,
                icon: RuleEngineIcon,
                expanded: false,
                selected: false,
                parentId: 2,
                hasItems: true,
                items: [
                    {
                        id: ROUTES.RULES,
                        text: SIDEBAR_ITEMS.RULES,
                        expanded: false,
                        selected: false,
                    },
                    {
                        id: ROUTES.SIMULATIONS,
                        text: SIDEBAR_ITEMS.SIMULATIONS,
                        expanded: false,
                        selected: false,
                    },
                ],
            },
        ] : [],
        {
            id: "multilevel-2",
            text: SIDEBAR_ITEMS.REPORTS,
            icon: ReportIcon,
            expanded: false,
            selected: false,
            parentId: 9,
            hasItems: true,
            items: [
                {
                    id: ROUTES.GAMERESULTSREPORT,
                    text: "Game Result",
                    expanded: false,
                    selected: false,
                },
                {
                    id: ROUTES.PAYMENTSREPORT,
                    text: "Payments",
                    expanded: false,
                    selected: false,
                },
                {
                    id: ROUTES.REVENUEREPORT,
                    text: "Revenue",
                    expanded: false,
                    selected: false,
                },
                {
                    id: ROUTES.CUSTOMERSREPORT,
                    text: "Customers",
                    expanded: false,
                    selected: false,
                }
            ],
        },
        {
            id: "multilevel-4",
            text: SIDEBAR_ITEMS.SETTINGS,
            icon: SettingIcon,
            expanded: false,
            selected: false,
            parentId: 9,
            hasItems: true,
            items: [
                {
                    id: ROUTES.RESET_CACHE,
                    text: SIDEBAR_ITEMS.RESET_CACHE,
                    expanded: false,
                    selected: false,
                }
            ],
        },
    ];

    return [sidebarMenuItems];
};
