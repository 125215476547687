import FactoryService from "../FactoryService";
import AccountingBaseService from "../AccountingBaseService";

const factoryService = new FactoryService();

class SubscriptionService {
    private SUBSCRIPTION_ENDPOINT = "api/Subscriptions";

    async getSubscription(ExcludeDefaultRegistration?: boolean, ExcludeDeactivated?: boolean) {

        let query = "";
        if (ExcludeDefaultRegistration) {
            query += `ExcludeDefaultRegistration=${ExcludeDefaultRegistration}&`;
        }
        if (ExcludeDeactivated) {
            query += `ExcludeDeactivatedItems=${ExcludeDeactivated}&`;
        }

        query = query.slice(0, -1);

        return await (
            await factoryService.create(AccountingBaseService)
        ).getAsJson(`${this.SUBSCRIPTION_ENDPOINT}/all?${query}`, true);
    }

    async addSubscription(subscription: any) {
        return await (
            await factoryService.create(AccountingBaseService)
        ).postAsJson(this.SUBSCRIPTION_ENDPOINT, subscription, true);
    }

    async toggleSubscription(id: string, active: boolean) {
        return await (
            await factoryService.create(AccountingBaseService)
        ).patchAsJson(`${this.SUBSCRIPTION_ENDPOINT}/${id}/activation`, active, true);
    }

    async getSubscriptionDetails(id: string) {
        return await (
            await factoryService.create(AccountingBaseService)
        ).getAsJson(`${this.SUBSCRIPTION_ENDPOINT}/${id}/details`, true);
    }

    async updateSubscription(id: string, payload: any) {
        return await (
            await factoryService.create(AccountingBaseService)
        ).putAsJson(`${this.SUBSCRIPTION_ENDPOINT}/${id}`, payload, true);
    }

    async addNewRate(subscriptionId: string, rate: any) {
        return await (
            await factoryService.create(AccountingBaseService)
        ).postAsJson(`${this.SUBSCRIPTION_ENDPOINT}/${subscriptionId}/addNewRate`, rate, true);
    }

    async deleteRate(rateId: string) {
        return await (
            await factoryService.create(AccountingBaseService)
        ).delete(`${this.SUBSCRIPTION_ENDPOINT}/${rateId}`, true);
    }

    async editRate(subscriptionId: string, payload: any) {
        const rateId = payload.id;
        const newPayload = {
            currencyIso3: payload.currencyIso3,
            amount: payload.amount,
            countryIso2: payload.countryIso2 || null,
        }

        return await (
            await factoryService.create(AccountingBaseService)
        ).putAsJson(`${this.SUBSCRIPTION_ENDPOINT}/${subscriptionId}/${rateId}`, newPayload, true);
    }


}

export default new SubscriptionService();
