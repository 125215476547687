import Popup from "devextreme-react/popup";
import TextBox from "devextreme-react/text-box";
import Validator, {PatternRule, RequiredRule} from "devextreme-react/validator";
import Button from "devextreme-react/button";
import checkValidation from "../../../../Utils/FormValidation";
import React, {useState} from "react";

import "./Style.scss";
import {COUNTRY_CODES, COUNTRY_CURRENCY, TOASTER_DELAY} from "../../../../Constants/values";
import {SelectBox} from "devextreme-react";
import {Column, DataGrid, SearchPanel} from "devextreme-react/data-grid";
import AddRate from "../AddRate/AddRate";
import notify from "devextreme/ui/notify";
import SubscriptionService from "../../../../Services/Subscription/Subscription";

type AddSubscriptionProps = {
    showAddPanel: boolean;
    closePopUpHandler: (value: boolean) => void;
    setLoading: (value: boolean) => void;
    callback?: () => void;
    currency: any[];
}

type SubscriptionRate = {
    currencyIso3: string;
    amount: number;
    countryIso2: string | null;
    defaultRate?: boolean;
}

type SubscriptionInformationProps = {
    name: string;
    durationInMonth: string;
    gracePeriodInDays: string;
    active: boolean;
    subscriptionRateList?: SubscriptionRate[];
}

const AddSubscription = ({showAddPanel, closePopUpHandler, setLoading, callback, currency}: AddSubscriptionProps) => {
    const [validationMessage, setValidationMessage] = useState<string[]>([]);
    const [subscriptionInformation, setSubscriptionInformation] = useState<SubscriptionInformationProps>({
        name: "",
        durationInMonth: "",
        gracePeriodInDays: "",
        active: true,
        subscriptionRateList: []
    });

    const [subscriptionRates, setSubscriptionRates] = useState<Array<SubscriptionRate>>([]);
    const [rate, setRate] = React.useState<SubscriptionRate>({
        currencyIso3: "",
        amount: 0,
        countryIso2: null,
        defaultRate: false
    });
    const [showAddRatePanel, setShowAddRatePanel] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);

    const onSubmit = () => {
        const payload = {
            ...subscriptionInformation,
            subscriptionRateList: subscriptionRates
        }

        const defaultRate = subscriptionRates.find((rate) => rate.defaultRate);
        if (!defaultRate) {
            notify("Default rate is required", "error", TOASTER_DELAY);
            return;
        }

        setLoading(true);
        SubscriptionService.addSubscription(payload).then(() => {
            notify("Subscription added successfully", "success", TOASTER_DELAY);
            closePopUpHandler(false);
            setLoading(false);
            callback && callback();
        }).catch((error) => {
            if (error.response) {
                const message = error.response.data;
                if (message === "DuplicateName") {
                    notify("Subscription name already exists", "error", TOASTER_DELAY);
                }
            } else {
                notify("Error while adding subscription", "error", TOASTER_DELAY);
            }

            setLoading(false);
        });

    };

    return (<React.Fragment>
        <Popup
            width={"50vw"}
            height={"90vh"}
            showTitle={true}
            title="Add Subscription"
            dragEnabled={false}
            visible={showAddPanel}
            onHiding={() => closePopUpHandler(false)}

        >
            <div>
                <div className="textboxContainer">
                    <TextBox
                        placeholder="Name"
                        value={subscriptionInformation.name}
                        onValueChange={(e) => setSubscriptionInformation({...subscriptionInformation, name: e})}
                    >
                        <Validator>
                            <RequiredRule message="Subscription Name"/>
                        </Validator>
                    </TextBox>
                </div>

                <div className="textboxContainer">
                    <TextBox
                        placeholder="Duration (Months)"
                        value={subscriptionInformation.durationInMonth}
                        onValueChange={(e) => setSubscriptionInformation({
                            ...subscriptionInformation,
                            durationInMonth: e
                        })}
                    >
                        <Validator>
                            <RequiredRule message="Duration is required"/>
                            <PatternRule
                                message="Duration must be a number"
                                pattern={/^\d+$/}/>

                        </Validator>
                    </TextBox>
                </div>

                <div className="textboxContainer">
                    <TextBox
                        placeholder="Grace Period (Days)"
                        value={subscriptionInformation.gracePeriodInDays}
                        onValueChange={(e) => setSubscriptionInformation({
                            ...subscriptionInformation,
                            gracePeriodInDays: e
                        })}
                    >
                        <Validator>
                            <RequiredRule message="Grace Period is required"/>
                            <PatternRule
                                message="Grace Period must be a number"
                                pattern={/^\d+$/}/>
                        </Validator>
                    </TextBox>
                </div>

                <div className="subscriptionTextboxContainer">
                    <Button
                        text="Add Rate"
                        disabled={
                            !subscriptionInformation.name || !subscriptionInformation.durationInMonth || !subscriptionInformation.gracePeriodInDays
                        }
                        onClick={() => {
                            setRate({
                                currencyIso3: "",
                                amount: 0,
                                countryIso2: null,
                                defaultRate: false
                            });

                            setShowAddRatePanel(true)
                        }}
                    />
                </div>

                <DataGrid
                    keyExpr={["id", "currencyIso3"]}
                    id="gridContainer"
                    className="grid-table"
                    dataSource={subscriptionRates}
                    showBorders={true}
                    defaultPaging={{pageSize: 5}}
                >
                    <SearchPanel visible={true}/>
                    <Column dataField="currencyIso3" caption="Currency" dataType="string"/>
                    <Column dataField="amount" caption="Amount" dataType="string"/>
                    <Column dataField="countryIso2" caption="Country" dataType="string"/>
                    <Column dataField="defaultRate" caption="Is Default" dataType="string"/>

                    <Column type="buttons" buttons={
                        [
                            {
                                name: "editRate",
                                onClick: (e: any) => {
                                    setIsEditMode(true);
                                    setRate(subscriptionRates[e.row.rowIndex]);
                                    setShowAddRatePanel(true);
                                },
                                icon: "edit",
                            },
                            {
                                name: "deleteRate",
                                onClick: (e: any) => {
                                    const updatedRates = subscriptionRates.filter((rate, index) => index !== e.row.rowIndex);
                                    setSubscriptionRates(updatedRates);

                                },
                                icon: "trash",
                            },
                        ]
                    } width="auto" allowEditing={false}></Column>
                </DataGrid>

                {validationMessage.length > 0 && (
                    <ul className="validation-message">
                        {validationMessage.map((msg: string) => (
                            <li key={msg} className="validation-message__item">
                                {msg}
                            </li>
                        ))}
                    </ul>
                )}

                <div className="textboxContainer">
                    <Button
                        text="Add Subscription"
                        useSubmitBehavior={true}
                        onClick={(e) => checkValidation(e, onSubmit)}
                    />
                </div>
            </div>
        </Popup>

        {showAddRatePanel &&
            <AddRate
                showAddRate={showAddRatePanel}
                closeAddRateHandler={(value) => setShowAddRatePanel(value)}
                setRateList={setSubscriptionRates}
                rateList={subscriptionRates}
                rate={rate}
                setRate={setRate}
                editMode={isEditMode}
                setEditMode={setIsEditMode}
                currency={currency}
            />}
    </React.Fragment>)
}

export default AddSubscription;