import {useState} from "react";
import {Button, LoadPanel} from "devextreme-react";
import notify from "devextreme/ui/notify";

import BlackoutService from "../../../Services/Blackout/BlackoutService";
import CreditService from "../../../Services/Credit/CreditService";

import {TOASTER_DELAY} from "../../../Constants/values";

import "./styles.scss";

const ResetCache = () => {
    const [loading, setLoading] = useState<boolean>(false)

    const resetCache = () => {
        setLoading(true)
        BlackoutService.ClearProfileCache().then(() => {
            CreditService.resetAccountingCache().then((res) => {
                   notify("Cache reset successful!!", 'success', TOASTER_DELAY);
                setLoading(false)
            }).catch((e) => {
                const error = e?.response?.data?.Error?.Message || "Something went wrong while resetting cache, please contact support"
                setLoading(false)
                notify(error, 'error', TOASTER_DELAY);
            })
        }).catch((e) => {
            setLoading(false)
            const error = e?.response?.data?.Error?.Message || "Something went wrong while resetting cache, please contact support"
            notify(error, 'error', TOASTER_DELAY);
        })
    }

    return (
        <div>
            <h1>Cache Settings</h1>
            <div className="reset-cache-subtitle">Manage system cache</div>

            <Button
                text="Reset Cache"
                type="default"
                disabled={loading}
                onClick={() => resetCache()}
            />

            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position={{of: "#simulations"}}
                visible={loading}
                showIndicator={true}
                shading={true}
                showPane={true}
                closeOnOutsideClick={false}
            />

        </div>
    )
}

export default ResetCache;