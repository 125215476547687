import FactoryService from "../FactoryService";
import ReportingBaseService from "../ReportingBaseService";
import TipBaseService from "../TipBaseService";
import AccountingBaseService from "../AccountingBaseService";

const factoryService = new FactoryService();

class ReportingService {
    private REPORTING_ENDPOINT = "api/Reporting/tip";
    private GAME_REPORTING_ENDPOINT = "FootballMatches/gemeResultReport"
    private GAME_REPORTING_EXPORT_ENDPOINT = "FootballMatches/gemeResultReport/export"
    private CUSTOMERS_REPORT_ENDPOINT = "api/Reporting/customer";
    private TRANSACTION_ENDPOINT = "api/Transactions";

    async getTransactions(externalReference: string, pageNumber: number, searchTerm: string | null) {

        const result = await (
            await factoryService.create(AccountingBaseService)
        ).getAsJson(`${this.TRANSACTION_ENDPOINT}/${externalReference}/customer?PageNumber=${pageNumber}${searchTerm ? `&Search=${searchTerm}` : ''}`, true);

        if (result) {
            return result;
        }

        return null;
    }

    async getGameResultReport(payload: any) {

        const result = await (
            await factoryService.create(TipBaseService)
        ).postAsJson(this.GAME_REPORTING_ENDPOINT, payload, true);

        if (result) {
            return result;
        }

        return null;
    }

    async exportGameResultReport(payload: any) {

        const result = await (
            await factoryService.create(TipBaseService)
        ).postAsJson(this.GAME_REPORTING_EXPORT_ENDPOINT, payload, true);

        if (result) {
            return result;
        }

        return null;
    }

    async getCustomerReport(payload: any, pageNumber: number = 1) {
        let queryString = "&";
        for (const key in payload) {
            if (payload[key] && payload[key].length > 0) {
                if (key === "startDate" || key === "keyword" || key === "endDate" || key === "botAccess") {
                    queryString += `${key}=${payload[key]}&`;
                    continue;
                }

                for (const value of payload[key]) {
                    queryString += `${key}=${value}&`;
                }
            }
        }

        queryString = queryString.slice(0, -1);

        const result = await (
            await factoryService.create(ReportingBaseService)
        ).getAsJson(`${this.CUSTOMERS_REPORT_ENDPOINT}?PageNumber=${pageNumber}${queryString}`, true);
        if (result) {
            return result;
        }
    }

    async customerReportExport(payload: any, isSubscriptionMode: boolean = false) {
        let queryString = "?";

        for (const key in payload) {
            if (payload[key] && payload[key].length > 0) {
                if (key === "startDate" || key === "keyword" || key === "endDate" || key === "botAccess") {
                    queryString += `${key}=${payload[key]}&`;
                    continue;
                }

                for (const value of payload[key]) {
                    queryString += `${key}=${value}&`;
                }
            }
        }

        queryString = queryString.slice(0, -1);

        const result = await (
            await factoryService.create(ReportingBaseService)
        ).getAsJson(`${this.CUSTOMERS_REPORT_ENDPOINT}/export${queryString}&subscriptionMode=${isSubscriptionMode}`, true);
        if (result) {
            return result;
        }
    }

}

export default new ReportingService();
