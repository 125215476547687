import ButtonBox from "devextreme-react/button";
import React, {useCallback, useEffect, useState} from "react";
import SubscriptionService from "../../../Services/Subscription/Subscription";

import {CheckBox} from "devextreme-react";
import Loader from "../../../Components/Loader";
import {Column, DataGrid, SearchPanel} from "devextreme-react/data-grid";
import AddSubscription from "./AddSubscription/AddSubscription";
import "./Styles.scss";
import notify from "devextreme/ui/notify";
import {TOASTER_DELAY} from "../../../Constants/values";
import EditSubscription from "./EditSubscription/EditSubscription";
import AssetsService from "../../../Services/Assets/AssetsService";

const SubscriptionConfiguration = () => {
    const [subscriptions, setSubscriptions] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [excludeDefaultRegistration, setExcludeDefaultRegistration] = React.useState();
    const [excludeDeactivated, setExcludeDeactivated] = React.useState();
    const [showAddPanel, setShowAddPanel] = useState(false);
    const [subscriptionId, setSubscriptionId] = useState<string>("");
    const [showEditPanel, setShowEditPanel] = useState(false);
    const [currency, setCurrency] = useState<any[]>([]);

    const getSubscriptions = () => {
        setIsLoading(true);
        SubscriptionService.getSubscription(excludeDefaultRegistration, excludeDeactivated).then((response) => {
            setSubscriptions(response?.result);
            setIsLoading(false);
        }).catch((error) => {
            console.log(error);
            setIsLoading(false);
        });
    }

    const getCurrencyHandler = () => {
        setIsLoading(true);
        AssetsService.getAvailableCurrency().then((response) => {
            setCurrency(response);
            setIsLoading(false);
        }).catch((err) => {
            notify("Error while fetching currencies", "error", TOASTER_DELAY);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        getSubscriptions();
    }, [
        excludeDefaultRegistration,
        excludeDeactivated,
    ]);

    useEffect(() => {
        getCurrencyHandler()
    }, []);

    return (
        <div
            className="subscriptionContainer"
        >
            <div
                className="subscriptionContainer__header"
            >
                <h1>Subscriptions</h1>
                <div>Manage subscriptions from here.</div>
            </div>

            <div
                className="subscriptionContainer__actionContainer"
            >
                <ButtonBox
                    text="Add Subscription"
                    className="subscriptionContainer__addSubscription"
                    onClick={() => setShowAddPanel(true)}
                ></ButtonBox>

                <CheckBox
                    value={excludeDefaultRegistration || false}
                    text="Hide Default Subscription"
                    onValueChanged={(e) => {
                        setExcludeDefaultRegistration(e.value);
                    }}
                />

                <CheckBox
                    value={excludeDeactivated || false}
                    text="Hide Deactivated Subscriptions"
                    onValueChanged={(e) => {
                        setExcludeDeactivated(e.value);
                    }}
                />

            </div>

            <DataGrid
                keyExpr={"id"}
                id="gridContainer"
                className="grid-table"
                dataSource={subscriptions}
                showBorders={true}
            >
                <SearchPanel visible={true}/>
                <Column dataField="id" visible={false} caption="ID" dataType="string"/>
                <Column dataField="name" caption="Name" dataType="string"/>
                <Column dataField="durationInMonth" caption="Duration (Months)" dataType="string"/>
                <Column dataField="gracePeriodInDays" caption="Grace Period (Days)" dataType="string"/>
                <Column dataField="active" caption="Status" dataType="string"/>
                <Column
                    dataField="subscriptionRateList"
                    caption="Country"
                    dataType="string"
                    cellRender={(e) => {
                        const countryList = e.data.subscriptionRateList.map((rate: any) => rate.countryIso2);
                        const commaSeparated = countryList.includes(null) ? `All, ${countryList.filter((country: any) => country !== null).join(", ")}` : countryList.join(", ");
                        const filtered = commaSeparated.replace(/,\s*$/, "");
                        const shortened = filtered.length > 20 ? filtered.substring(0, 20) + "..." : filtered;

                        return (
                            <div title={commaSeparated}>
                                {shortened}
                            </div>
                        );

                    }}
                />
                <Column dataField="defaultRegistration" caption="Default Registration" dataType="string"/>
                <Column dataField="createdOn" caption="Created On" format="dd/MM/yyyy" dataType="date"/>
                <Column
                    type="buttons"
                    buttons={
                        [
                            {
                                name: "editSubscription",
                                onClick: (e: any) => {
                                    setSubscriptionId(e.row.data.id);
                                    setShowEditPanel(true);
                                },
                                icon: "edit",
                            }
                        ]
                    } width="auto" allowEditing={false}></Column>
            </DataGrid>

            <Loader isLoaderVisible={isLoading}/>


            {
                showAddPanel && (<AddSubscription
                    showAddPanel={showAddPanel}
                    closePopUpHandler={setShowAddPanel}
                    setLoading={setIsLoading}
                    callback={getSubscriptions}
                    currency={currency}
                />)
            }

            {
                showEditPanel && (<EditSubscription
                    showEditPanel={showEditPanel}
                    subscriptionId={subscriptionId}
                    closePopUpHandler={setShowEditPanel}
                    setLoading={setIsLoading}
                    callback={getSubscriptions}
                    currency={currency}
                />)
            }

        </div>
    );
}

export default SubscriptionConfiguration;