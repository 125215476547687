import React from "react";
import Popup from "devextreme-react/popup";
import {CheckBox, SelectBox} from "devextreme-react";
import Validator, {PatternRule, RequiredRule} from "devextreme-react/validator";
import TextBox from "devextreme-react/text-box";
import Button from "devextreme-react/button";
import notify from "devextreme/ui/notify";

import checkValidation from "../../../../Utils/FormValidation";
import {COUNTRY_CODES, TOASTER_DELAY} from "../../../../Constants/values";
import SubscriptionService from "../../../../Services/Subscription/Subscription";

import "./Style.scss";

type SubscriptionRate = {
    id?: string;
    currencyIso3: string;
    amount: number;
    countryIso2: string | null;
    defaultRate?: boolean;
}

type EditRateProps = {
    showEditRate: boolean;
    closeEditRateHandler: (value: boolean) => void;
    rateList: SubscriptionRate[];
    rate: SubscriptionRate;
    editMode: boolean;
    setEditMode: (value: boolean) => void;
    setRate: (value: SubscriptionRate) => void;
    subscriptionId: string;
    callback?: () => void;
    setLoading: (value: boolean) => void;
    currency: any[];
}
const EditRate = ({
                      showEditRate,
                      closeEditRateHandler,
                      rateList,
                      setRate,
                      rate,
                      editMode,
                      setEditMode,
                      subscriptionId,
                      callback,
                      setLoading,
                      currency
                  }: EditRateProps) => {

    const addEditRateHandler = () => {
        if (editMode) {
            const payload = {
                id: rate.id,
                currencyIso3: rate?.currencyIso3,
                amount: rate?.amount,
                countryIso2: rate?.countryIso2 || null,
            }
            setLoading(true);
            SubscriptionService.editRate(subscriptionId, payload).then(() => {
                notify("Rate edited successfully", "success", TOASTER_DELAY);
                callback && callback();
                setLoading(false);
            }).catch((err) => {
                console.log(err);
                notify("Error while editing rate", "error", TOASTER_DELAY);
                setLoading(false);
            });

            setEditMode(false);
            closeEditRateHandler(false);
        } else {
            const duplicate = rateList.find((r) => r.countryIso2 === rate.countryIso2);
            if (duplicate && !rate.defaultRate) {
                notify("Rate for this country already exists", "error", TOASTER_DELAY);
                return;
            }

            const payload = {
                currencyIso3: rate.currencyIso3,
                amount: rate.amount,
                countryIso2: rate.countryIso2,
            }

            setLoading(true);
            SubscriptionService.addNewRate(subscriptionId, payload).then(() => {
                notify("Rate added successfully", "success", TOASTER_DELAY);
                setLoading(false);
                callback && callback();
            }).catch((err) => {
                notify("Error while adding rate", "error", TOASTER_DELAY);
                console.log(err);
                setLoading(false);
            });

            closeEditRateHandler(false);
        }
    };

    const defaultRateChecker = () => {
        return rateList.some((r) => r.defaultRate);
    }

    return (
        <Popup
            width={"30vw"}
            height={"50vh"}
            showTitle={true}
            title={`${editMode ? "Edit" : "Add"} Rate`}
            dragEnabled={false}
            visible={showEditRate}
            onHiding={() => {
                closeEditRateHandler(false)
                setEditMode(false);
            }}
        >
            <div>
                <div className="addRateTextboxContainer">
                    {!defaultRateChecker() &&
                        <CheckBox
                            text="Default Rate"
                            value={rate?.defaultRate || false}
                            onValueChanged={(e) => {
                                setRate({...rate, defaultRate: e.value});
                            }}
                        ></CheckBox>
                    }
                </div>

                <div className="addRateTextboxContainer">
                    <SelectBox
                        dataSource={COUNTRY_CODES}
                        displayExpr={"name"}
                        disabled={rate.defaultRate}
                        value={
                            rate?.countryIso2 || null
                        }
                        valueExpr={"code"}
                        searchEnabled={true}
                        onValueChanged={(e) => {
                            setRate({...rate, countryIso2: e.value});
                        }}
                        placeholder="Country"
                    >
                        <Validator>
                            {
                                rate.defaultRate ? null : <RequiredRule message="Country is required"/>
                            }
                        </Validator>
                    </SelectBox>
                </div>

                <div className="addRateTextboxContainer">
                    <SelectBox
                        dataSource={currency}
                        value={rate?.currencyIso3 || null}
                        onValueChanged={
                            (e) => {
                                setRate({...rate, currencyIso3: e.value});
                            }
                        }
                        placeholder="Currency"
                        searchEnabled={true}
                        searchMode="contains"
                        searchExpr="this"
                        searchTimeout={200}
                        minSearchLength={0}
                        showClearButton={true}
                    >
                        <Validator>
                            <RequiredRule message="Currency is required"/>
                        </Validator>
                    </SelectBox>
                </div>

                <div className="addRateTextboxContainer">
                    <TextBox
                        placeholder="Amount"
                        value={String(rate?.amount || "")}
                        onValueChange={(e) => {
                            setRate({...rate, amount: Number(e)});
                        }}
                    >
                        <Validator>
                            <RequiredRule message="Amount is required"/>
                            <PatternRule
                                message="Amount must be a number"
                                pattern={/^\d+(\.\d+)?$/}
                            />
                        </Validator>
                    </TextBox>
                </div>

                <div className="textboxContainer">
                    <Button
                        text={`${editMode ? "Edit" : "Add"} Rate`}
                        onClick={(e) => checkValidation(e, addEditRateHandler)}
                    />
                </div>
            </div>
        </Popup>
    );
}

export default EditRate;