import Popup from "devextreme-react/popup";
import TextBox from "devextreme-react/text-box";
import Validator, {PatternRule, RequiredRule} from "devextreme-react/validator";
import Button from "devextreme-react/button";
import checkValidation from "../../../../Utils/FormValidation";
import React, {useCallback, useEffect, useState} from "react";

import "./Style.scss";
import {TOASTER_DELAY} from "../../../../Constants/values";
import {Column, DataGrid, SearchPanel} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import SubscriptionService from "../../../../Services/Subscription/Subscription";
import EditRate from "../EditRate/EditRate";
import {confirm} from "devextreme/ui/dialog";

type EditSubscriptionProps = {
    showEditPanel: boolean;
    closePopUpHandler: (value: boolean) => void;
    setLoading: (value: boolean) => void;
    callback?: () => void;
    subscriptionId: string;
    currency: any[];
}

type SubscriptionRate = {
    id?: string;
    currencyIso3: string;
    amount: number;
    countryIso2: string | null;
    defaultRate?: boolean;
}

type SubscriptionInformationProps = {
    id?: string;
    name: string;
    durationInMonth: string;
    gracePeriodInDays: string;
    active: boolean;
    subscriptionRateList?: SubscriptionRate[];
}

const EditSubscription = ({
                              showEditPanel,
                              closePopUpHandler,
                              setLoading,
                              callback,
                              subscriptionId,
                              currency
                          }: EditSubscriptionProps) => {
    const [subscriptionInformation, setSubscriptionInformation] = useState<SubscriptionInformationProps>({
        name: "",
        durationInMonth: "",
        gracePeriodInDays: "",
        active: true,
        subscriptionRateList: []

    });

    const [subscriptionRates, setSubscriptionRates] = useState<Array<SubscriptionRate>>([]);
    const [rate, setRate] = React.useState<SubscriptionRate>({
        currencyIso3: "",
        amount: 0,
        countryIso2: null,
        defaultRate: false
    });
    const [showEditRatePanel, setShowEditRatePanel] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);

    const fetchSubscriptionDetail = () => {
        setLoading(true)
        SubscriptionService.getSubscriptionDetails(subscriptionId).then((res) => {
            setSubscriptionInformation(res);
            setSubscriptionRates(res.subscriptionRateList)
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }

    const onSubmit = () => {
        const payload = {
            name: subscriptionInformation.name,
            durationInMonth: subscriptionInformation.durationInMonth,
            gracePeriodInDays: subscriptionInformation.gracePeriodInDays,
            active: subscriptionInformation.active,
        }

        setLoading(true);
        SubscriptionService.updateSubscription(subscriptionId, payload).then(() => {
            notify("Subscription updated successfully", "success", TOASTER_DELAY);
            closePopUpHandler(false);
            setLoading(false);
            callback && callback();
        }).catch((error) => {
            if (error.response) {
                const message = error.response.data;
                if (message === "DuplicateName") {
                    notify("Subscription name already exists", "error", TOASTER_DELAY);
                }
            } else {
                notify("Error while updating subscription", "error", TOASTER_DELAY);
            }

            setLoading(false);
        });
    };

    const deleteRateHandler = (rateId: string) => {
        setLoading(true);
        SubscriptionService.deleteRate(rateId).then(() => {
            notify("Rate deleted successfully", "success", TOASTER_DELAY);
            setLoading(false);
            fetchSubscriptionDetail();
        }).catch((err) => {
            console.log(err)
            setLoading(false);
            notify("Error while deleting rate", "error", TOASTER_DELAY);
        });
    }

    const subscriptionStatusHandler = useCallback((subscriptionId: string, subscriptionStatus: boolean) => {
        let result = confirm(
            "<i>Are you sure you want to change the status of this subscription? <b>",
            "Please confirm your action"
        );

        result.then((dialogResult) => {
            if (dialogResult) {
                setLoading(true);
                SubscriptionService.toggleSubscription(subscriptionId, subscriptionStatus)
                    .then(() => {
                        notify("Status updated successfully", "success", TOASTER_DELAY);
                        setLoading(false);
                    })
                    .catch(() => {
                        notify("Error while updating subscription status.", "error", TOASTER_DELAY);
                        setLoading(false);
                    })
                    .finally(() => {
                        callback && callback();
                        closePopUpHandler(false);
                    });
            }
        });
    }, []);

    useEffect(() => {
        fetchSubscriptionDetail()
    }, []);

    return (<React.Fragment>
        <Popup
            width={"50vw"}
            height={"90vh"}
            showTitle={true}
            title="Edit Subscription"
            dragEnabled={false}
            visible={showEditPanel}
            onHiding={() => closePopUpHandler(false)}
        >
            <div>
                <div className="textboxContainer">
                    <TextBox
                        placeholder="Name"
                        value={subscriptionInformation.name}
                        onValueChange={(e) => setSubscriptionInformation({...subscriptionInformation, name: e})}
                    >
                        <Validator>
                            <RequiredRule message="Subscription Name"/>
                        </Validator>
                    </TextBox>
                </div>

                <div className="textboxContainer">
                    <TextBox
                        placeholder="Duration (Months)"
                        value={subscriptionInformation.durationInMonth}
                        onValueChange={(e) => setSubscriptionInformation({
                            ...subscriptionInformation,
                            durationInMonth: e
                        })}
                    >
                        <Validator>
                            <RequiredRule message="Duration is required"/>
                            <PatternRule
                                message="Duration must be a number"
                                pattern={/^\d+$/}/>

                        </Validator>
                    </TextBox>
                </div>

                <div className="textboxContainer">
                    <TextBox
                        placeholder="Grace Period (Days)"
                        value={subscriptionInformation.gracePeriodInDays}
                        onValueChange={(e) => setSubscriptionInformation({
                            ...subscriptionInformation,
                            gracePeriodInDays: e
                        })}
                    >
                        <Validator>
                            <RequiredRule message="Grace Period is required"/>
                            <PatternRule
                                message="Grace Period must be a number"
                                pattern={/^\d+$/}/>
                        </Validator>
                    </TextBox>
                </div>

                <div className="subscriptionTextboxContainer">
                    <Button
                        text="Update Subscription"
                        style={{
                            marginRight: "10px"
                        }}
                        useSubmitBehavior={true}
                        onClick={(e) => checkValidation(e, onSubmit)}
                    />

                    <Button
                        text="Add Rate"
                        disabled={
                            !subscriptionInformation.name || !subscriptionInformation.durationInMonth || !subscriptionInformation.gracePeriodInDays
                        }
                        onClick={() => {
                            setRate({
                                currencyIso3: "",
                                amount: 0,
                                countryIso2: null,
                                defaultRate: false
                            });

                            setShowEditRatePanel(true)
                        }}
                    />

                    <Button
                        text={`${subscriptionInformation.active ? "Deactivate" : "Activate"} Subscription`}
                        className="subscriptionButtonMarginLeft"
                        onClick={(e)=> {
                            subscriptionStatusHandler(subscriptionId, !subscriptionInformation.active);
                        }}
                    />
                </div>

                <DataGrid
                    keyExpr={["currencyIso3", "id"]}
                    id="gridContainer"
                    className="grid-table"
                    dataSource={subscriptionRates}
                    showBorders={true}
                    defaultPaging={{pageSize: 5}}
                >
                    <SearchPanel visible={true}/>
                    <Column dataField="currencyIso3" caption="Currency" dataType="string"/>
                    <Column dataField="amount" caption="Amount" dataType="string"/>
                    <Column dataField="countryIso2" caption="Country" dataType="string"/>
                    <Column dataField="defaultRate" caption="Is Default" dataType="string"/>
                    <Column type="buttons" buttons={
                        [
                            {
                                name: "editRate",
                                onClick: (e: any) => {
                                    setIsEditMode(true);
                                    setRate(subscriptionRates[e.row.rowIndex]);
                                    setShowEditRatePanel(true);
                                },
                                icon: "edit",
                            },
                            {
                                name: "deleteRate",
                                visible: (e: any) => !e.row.data.defaultRate,
                                onClick: (e: any) => deleteRateHandler(e.row.data.id),
                                icon: "trash",
                            },
                        ]
                    } width="auto" allowEditing={false}></Column>
                </DataGrid>
            </div>
        </Popup>

        {showEditRatePanel &&
            <EditRate
                showEditRate={showEditRatePanel}
                closeEditRateHandler={(value) => setShowEditRatePanel(value)}
                rateList={subscriptionRates}
                rate={rate}
                setRate={setRate}
                editMode={isEditMode}
                setEditMode={setIsEditMode}
                subscriptionId={subscriptionId}
                callback={fetchSubscriptionDetail}
                setLoading={setLoading}
                currency={currency}
            />}
    </React.Fragment>)
}

export default EditSubscription;