import {Button} from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import {useState} from 'react';
import Loader from '../../../../Components/Loader';
import {TOASTER_DELAY} from '../../../../Constants/values';
import {TopupReportPayloadDTO} from '../../../../Models/TopupReport/TopupReport';
import TopupReport from '../../../../Services/TopupReport/TopupReport';
import {DownloadBase64File} from '../../../../Utils/FileHandler';

type Props = {
    revenueReportPayload: TopupReportPayloadDTO;
};

const RevenueReportExportBtn = ({revenueReportPayload}: Props) => {
    const [Loading, setLoading] = useState(false);
    const handleExport = () => {
        setLoading(true);
        const payload: TopupReportPayloadDTO = {
            userExternalIds: revenueReportPayload.userExternalIds,
            taxCountries: revenueReportPayload.taxCountries,
            startDate: revenueReportPayload.startDate,
            endDate: revenueReportPayload.endDate,
            keyword: revenueReportPayload.keyword,
            transactionStatusIds: revenueReportPayload.transactionStatusIds,
        }
        TopupReport.exportReport(payload)
            .then((res) => {
                DownloadBase64File(
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'RevenueReport.xlsx',
                    res
                );
                notify('Successfully downloaded', 'success', TOASTER_DELAY);
                setLoading(false);
            })
            .catch((err) => {
                err?.response?.data.text().then((res: string) => {
                    const errorMsg = res;
                    errorMsg && notify(errorMsg, 'error', TOASTER_DELAY);
                    setLoading(false);
                });
            });
    };

    return (
        <article>
            <Button
                text="Export"
                type="default"
                id="topupSearch"
                useSubmitBehavior={true}
                onClick={handleExport}
            />
            <Loader isLoaderVisible={Loading}/>
        </article>
    );
};

export default RevenueReportExportBtn;
