import {useState} from "react";
import Button from "devextreme-react/button";
import Popup from "devextreme-react/popup";
import TextBox from "devextreme-react/text-box";
import Validator, {
    PatternRule,
    RequiredRule,
} from "devextreme-react/validator";
import notify from "devextreme/ui/notify";

import UserDto from "../../Models/Users/UserDto";
import BackendUsersService from "../../Services/BackendUsers/BackendUsersService";

import checkValidation from "../../Utils/FormValidation";
import checkPasswordValidation from "../../Utils/PasswordValidation";

import {TOASTER_DELAY} from "../../Constants/values";

import "./AddBackendUser.scss";

type AddBackendUserPopupProps = {
    showAddPanel: boolean;
    closePopUpHandler: (value: boolean) => void;
    getBackendUsers: () => void;
    roleLookupData: string[];
    backendInformation: UserDto;
    setBackendUserInformation: (value: UserDto) => void;
    passwordDisabled: boolean;
    password: string;
    setPassword: (value: string) => void;
}

const AddBackendUserPopup = (
    {
        showAddPanel,
        closePopUpHandler,
        backendInformation,
        setBackendUserInformation,
        roleLookupData,
        passwordDisabled,
        password,
        setPassword,
        getBackendUsers
    }: AddBackendUserPopupProps) => {
    const [checked, setChecked] = useState<string[]>(backendInformation.Roles ?? []);
    const [checkedClient, setClientChecked] = useState<string[]>(backendInformation.Clients ?? []);
    const [validationMessage, setValidationMessage] = useState<string[]>([]);


    const handleCheck = (event: any) => {
        if (event.target.checked) {
            setChecked([...checked, event.target.value]);
        } else {
            setChecked(checked.filter((x) => x !== event.target.value));
        }
    };

    const handleClientCheck = (event: any) => {
        if (event.target.checked) {
            setClientChecked([...checkedClient, event.target.value]);
        } else {
            setClientChecked(checkedClient.filter((x) => x !== event.target.value));
        }
    };

    const onSubmit = () => {
        const data: UserDto = {
            Email: backendInformation.Email,
            Name: backendInformation.Name,
            FamilyName: backendInformation.FamilyName,
            Roles: checked,
            UserName: backendInformation.Email,
            Clients: checkedClient,
            Password: password,
        };

        BackendUsersService.AddBackendUser(data)
            .then((res) => {
                setBackendUserInformation({
                    ...backendInformation,
                    Email: "",
                    Name: "",
                    FamilyName: "",
                    UserName: "",
                    Roles: [],
                    Clients: [],
                })

                setPassword("");
                closePopUpHandler(false);
                getBackendUsers()
                notify("Backend user added successfully", "success", TOASTER_DELAY);
            })
            .catch((err) => {
                let validationMessageArray = checkPasswordValidation(password);
                setValidationMessage([...validationMessageArray]);
                notify("Failed adding: " + err, "error", TOASTER_DELAY);
            });
    };

    return (
        <Popup
            width={660}
            height={540}
            showTitle={true}
            title="Add Backend User"
            dragEnabled={false}
            visible={showAddPanel}
            onHiding={() => closePopUpHandler(false)}
        >
            <div>
                <div className="textboxContainer">
                    <TextBox
                        placeholder="Email Address"
                        value={backendInformation.Email}
                        onValueChange={(e) => setBackendUserInformation({...backendInformation, Email: e})}
                    >
                        <Validator>
                            <RequiredRule message="Email Address is required"/>
                            <PatternRule
                                message="Email entered is incorrect"
                                pattern={/^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/g}
                            />
                        </Validator>
                    </TextBox>
                </div>
                <div className="textboxContainer">
                    <TextBox
                        placeholder="First name"
                        value={backendInformation.Name}
                        onValueChange={(e) => setBackendUserInformation({...backendInformation, Name: e})}
                    >
                        <Validator>
                            <RequiredRule message="First name is required"/>
                        </Validator>
                    </TextBox>
                </div>
                <div className="textboxContainer">
                    <TextBox
                        placeholder="Last name"
                        value={backendInformation.FamilyName}
                        onValueChange={(e) => setBackendUserInformation({...backendInformation, FamilyName: e})}
                    >
                        <Validator>
                            <RequiredRule message="Last name is required"/>
                        </Validator>
                    </TextBox>
                </div>
                <div className="lefttextboxContainer">
                    <fieldset className="user__fieldset">
                        <legend>Roles:</legend>
                        <ul className="user__roles">
                            {roleLookupData.map((item, index) => {
                                if (item === "Client") return;
                                return (
                                    <li className="user__role" key={item + index + 1}>
                                        <input
                                            id={"role_" + item}
                                            value={item}
                                            type="checkbox"
                                            onChange={handleCheck}
                                        />
                                        <label htmlFor={"role_" + item} className={"noselect"}>
                                            {item}
                                        </label>
                                    </li>
                                );
                            })}
                        </ul>
                    </fieldset>
                </div>
                <div className="lefttextboxContainer">
                    <TextBox
                        disabled={passwordDisabled}
                        mode="password"
                        placeholder="Password"
                        value={password}
                        onValueChange={(e) => setPassword(e)}
                    >
                        <Validator>
                            <RequiredRule message="Password is required"/>
                        </Validator>
                    </TextBox>
                </div>
                {validationMessage.length > 0 && (
                    <ul className="validation-message">
                        {validationMessage.map((msg: string) => (
                            <li key={msg} className="validation-message__item">
                                {msg}
                            </li>
                        ))}
                    </ul>
                )}
                <div className="textboxContainer">
                    <Button
                        text="Add Backend User"
                        className="addUser"
                        useSubmitBehavior={true}
                        onClick={(e) => checkValidation(e, onSubmit)}
                    />
                </div>
            </div>
        </Popup>
    );
}

export default AddBackendUserPopup;
