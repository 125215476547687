import {Button} from "devextreme-react";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";
import {ReactComponent as LogoIcon} from "../../Assets/images/logo.svg";

import {ROUTES} from "../../routes";
import AuthService from "../../Services/Auth/AuthService";
import HamburgerButton from "../HamburgerButton";
import "./Header.scss";

type Props = {
    handleShowSidebar: React.Dispatch<React.SetStateAction<any>>;
    showSidebar: boolean;
};

export const Header = ({handleShowSidebar, showSidebar}: Props) => {
    const [userFullName, setUserFullName] = useState("");
    useEffect(() => {
        const userName = localStorage.getItem("userEmail");
        setUserFullName(userName ?? "");
    }, []);

    const navigation = useNavigate();
    return (
        <header className="header">
            <nav className="header__nav">
                <ul className="header__list">
                    <li className="header__item">
                        {" "}
                        <HamburgerButton
                            // isOpened={showSidebar}
                            onClick={() => handleShowSidebar(!showSidebar)}
                        />
                    </li>
                    <li className="header__item">
                        {" "}
                        {!showSidebar && (
                            <Link
                                to={"/"}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    textDecoration: "none",
                                    padding: "0 1.5rem",
                                    marginTop: "0.5rem",
                                    fontSize: "1.5rem",
                                    fontWeight: "bold",
                                }}
                                className="header-collapsed"
                            >
                                <LogoIcon className="header-collapsed-icon"/>
                            </Link>
                        )}
                    </li>
                    <li className="header__item">
                        {" "}
                        <p className="header__welcome-msg" id="userFullName">
                            Welcome! <b>{userFullName}</b>{" "}
                        </p>
                        <Button
                            text="Log out"
                            id="logout_BTN"
                            type="default"
                            className="header__logout-btn"
                            onClick={() => {
                                AuthService.logout();
                                navigation(ROUTES.LOGIN, {replace: true});
                            }}
                        />
                    </li>
                </ul>
            </nav>
        </header>
    );
};
